import React, { Component } from "react";
import Config from "../config.json";
import { Link,Redirect } from 'react-router-dom';
import '../static/dashboard.css';
import logo2 from '../static/logo2.png';
import Header from './Header';
class Dashboard extends Component{
	constructor(){
		super();
		const token = localStorage.getItem("token");
		let login_status = true;
		if(token === null){
			login_status = false;
		}
		this.state = {
			login_status,
			token,
			webinar_array : null,
			isLoaded:false
		}

		// const data = { token:token };

			

	}

	function_logout(e){
		return <Redirect to="/logout" />
	}

	update_status(id,status,i){
		
		if(window.confirm('Ready to start the class ?')){

			(async (id,status) => {
				
			  const rawResponse = await fetch(Config.SERVER_URL + '/update-webinar/?id='+id+'&status='+status, {
			    method: 'GET',
			    headers: {
			      'Accept': 'application/json',
			      'Content-Type': 'application/json',
			      'Authorization': 'Token '+this.state.token,
			    },
			    // body: JSON.stringify(data)
			  });
			  const content = await rawResponse.json();
			  console.log(content);
			  

			})(id,status);

			// this.setState({
				this.state.webinar_array[i].class_status = status;
				this.forceUpdate();
			// });
			
		}

		
		
	}


	componentDidMount(){
		(async () => {
			// console.log(this.state.token);
		  const rawResponse = await fetch(Config.SERVER_URL + '/get-webinars/', {
		    method: 'GET',
		    headers: {
		      'Accept': 'application/json',
		      'Content-Type': 'application/json',
		      'Authorization': 'Token '+this.state.token,
		    },
		    // body: JSON.stringify(data)
		  });
		  const content = await rawResponse.json();

		  this.setState({
		  	webinar_array:content
		  });

		  this.setState({
		  	isLoaded:true
		  });

		})();
		
	}


	render(){
		if(this.state.login_status === false){
			return <Redirect to="/" />
		}

		if(this.state.isLoaded){		

			return(
				<div>
					<Header />

					<div className="container">
						<div className="row">

						{this.state.webinar_array.map((item,i) => (
							<div className="col-md-3" key={i}>
								<div className="main">
									<div className="service">

										<h4>{item.studio_assoc.studio_name}</h4>
										<hr />
										<span><h5>Class : {item.name} </h5> </span>
										<span><h5>Subject : {item.subject.subject_name} </h5> </span>
										<span><h5>Teacher : {item.teacher_assoc.teacher_name} </h5> </span>
										<span><h5>Class start at : {item.scheduled_start_time} </h5> </span>
										
											
											


											      {item.class_status === "Live" &&
											      <span>
											      	<button className="button3">Live</button>
												    <button className="button4" onClick={() => this.update_status(item.id,"Issue",i)}>Report</button>
											      
												    </span>
											      }

											      {item.class_status === "Start" &&
											      <span> <h5> Studio Status:
											    		<button className="button button1" onClick={() => this.update_status(item.id,"Live",i)}>{item.class_status}</button></h5> </span>
											      }

											      {item.class_status === "Issue" &&
											      <span> <h5> Studio Status:
											      		<button className="button button1">{item.class_status}</button></h5> </span>
											      }

											      
										
											
										
									</div>
								</div>
							</div>
							))};
							
							
							
							
						</div>
					</div>
				</div>
				);
		}else{
			return(
				<div>Loading...</div>
				);
		}
	}
}

export default Dashboard;