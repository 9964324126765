import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Config from "../config.json";
import ReactDOM from "react-dom";
import { Multiselect } from "multiselect-react-dropdown";
import Modal from "react-modal";
import ReactLoading from "react-loading";
import Moment from "moment";
import "../static/dashboard.css";
import logo2 from "../static/ZinEdu_LOGO.svg";
import "../static/sch.css";
import Header from "./Header";
Modal.setAppElement("#root");
class TimeTable extends Component {
  constructor() {
    super();
    const token = localStorage.getItem("token");

    let login_status = true;
    if (token === null) {
      login_status = false;
    }
    this.state = {
      login_status,
      token,
      isLoaded: true,
      isLoaded2: false,
      id: null,
      start_time: null,
      end_time: null,
      from_date: null,
      to_date: null,
      duration: null,
      confliction_id: null,
      group_available: [],
      teacher_available: [],
      subject_available: [],
      studio_available: [],
      center_available: [],
      status_available: [],
      json_list: [],
      chapter_list: [],
      tag_list: [],
      selected_tag_list: [],
      tags: [],
	  ids: [],
      teacher: null,
      group: null,
      topic: null,
      subject: null,
      chapter: null,
      studio: null,
      center: null,
      status: null,
	  schedule_type: null,
      desc: null,
      studio_name: null,
      center_name: null,
      group_name: null,
      teacher_name: null,
      subject_name: null,
      chapter_name: null,
      tags_detail: null,
      class_start_time: null,
      class_end_time: null,
      class_duration: null,
      join_url: null,
      zoom_record_url: null,
      class_status: null,
      teacher_text: "Faculty",
      subject_text: "Subject",
      group_text: "Group",
      studio_text: "Studio",
      status_text: "Status",
      chapter_text: "Chapter",
      tag_text: "Subtopics",
      center_text: "Center",
	  schedule_type_id: 0,
	  schedule_type_text: "Online",
	  schedule_type_id2: null,
	  schedule_type_text2: "Class Mode",
	  class_type_id: 0,
	  class_type_text: 'Class Type',
      reason: null,
	  reason_text: 'Select Reason',
	  reason_teacher_id: null,
	  reason_center_id: null,
      popup_dialog: false,
      popup_dialog2: false,
      popup_dialog3: false,
      popup_dialog4: false,
      order_by: "",
      order_field: "start_time",
    };
    this.onChangeHandlerFromDate = this.onChangeHandlerFromDate.bind(this);
    this.onChangeHandlerToDate = this.onChangeHandlerToDate.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.onSubmitHandler2 = this.onSubmitHandler2.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onSearchHandler = this.onSearchHandler.bind(this);
  }

  async onSearchHandler(e) {
    this.setState({
      isLoaded: true,
    });
    let from_date = this.state.from_date;
    let to_date = this.state.to_date;
    let group = this.state.group;
    let subject = this.state.subject;
    let studio = this.state.studio;
    let teacher = this.state.teacher;
    let status = this.state.status;
	let class_type = this.state.class_type_id;
	let schedule_type = this.state.schedule_type_id2;
    let url = "";
    let query_string = "";
    if (from_date != null && to_date != null) {
      query_string =
        query_string + "&from_date=" + from_date + "&to_date=" + to_date;
    }
    if (teacher != 0 && teacher != null) {
      query_string = query_string + "&teacher_id=" + teacher;
    }
    if (subject != 0 && subject != null) {
      query_string = query_string + "&subject_id=" + subject;
    }
    if (studio != 0 && studio != null) {
      query_string = query_string + "&studio_id=" + studio;
    }
    if (group != 0 && group != null) {
      query_string = query_string + "&group_id=" + group;
    }
	if (class_type != 0 && class_type != null) {
      query_string = query_string + "&class_type=" + class_type;
    }
	if (schedule_type != 0 && schedule_type != null) {
      query_string = query_string + "&schedule_type=" + schedule_type;
    }
    if (status != 0 && status != null) {
      query_string = query_string + "&status_id=" + status;
    }
    query_string =
      query_string +
      "&order_by=" +
      this.state.order_by +
      "&order_field=" +
      this.state.order_field;
    if (query_string != null) {
      url = "/get-time-table?" + query_string;
    } else {
      url = "/get-time-table";
    }

    const rawResponse = await fetch(Config.SERVER_URL + url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + this.state.token,
      },
    });
    const response = await rawResponse.json();
    this.setState({
      json_list: response.data,
      isLoaded: false,
    });
  }

  async onChangeHandlerFromDate(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  async onChangeHandlerToDate(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  onChangeHandler(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  select_reason(res) {
    this.setState({ reason: res, reason_text: res });
  }
  redirect_conflict_page() {
    return <Redirect to="/conflict-timetable" />;
  }
  async select_order(order_field, order_by) {
    this.setState({
      order_field: order_field,
    });
    this.setState({
      order_by: order_by,
    });
  }
  onSelect(selectedList, selectedItem) {
    let selected_tag_lists = [];
    for (const s of selectedList) {
      selected_tag_lists.push({ id: s.id, tag_name: s.tag_name });
    }
    this.setState({
      selected_tag_list: selected_tag_lists,
    });
  }
  onRemove(selectedList, removedItem) {
    let selected_tag_lists = [];
    for (const s of selectedList) {
      selected_tag_lists.push({ id: s.id, tag_name: s.tag_name });
    }
    this.setState({
      selected_tag_list: selected_tag_lists,
    });
  }

  show_dialog_modal() {
    this.setState({
      popup_dialog: true,
    });
  }
  hide_dialog_modal() {
    this.setState({
      popup_dialog: false,
      popup_dialog2: false,
      popup_dialog3: false,
	  popup_dialog4: false,
    });
  }
  
  select_schedule_type2(id, schedule_type_name) {
    this.setState({
      schedule_type_id2: id,
      schedule_type_text2: schedule_type_name,
    });
  }
  select_schedule_type(id, schedule_type_name) {
    this.setState({
      schedule_type_id: id,
      schedule_type_text: schedule_type_name,
    });
	if(id == 0){
	this.setState({
      center: null,
      center_text: "Select Center",
    });
	}
	else{
		this.setState({
      studio: null,
      studio_text: "Select Studio",
    });
	}
  }
  
  select_class_type(id, class_type_name) {
    this.setState({
      class_type_id: id,
      class_type_text: class_type_name,
    });
  }
  
  select_group(id, group_name) {
    this.setState({
      group: id,
      group_text: group_name,
    });
  }

  select_center(id, center_name) {
    this.setState({
      center: id,
      center_text: center_name,
    });
  }

select_center3 (id, center_name) {
    this.setState({
      reason_center_id: id,
      center_text: center_name
    })
  }
  
  select_subject(id, subject_name) {
    this.setState({
      subject: id,
      subject_text: subject_name,
    });
    this.get_chapters(id, "Chapter");
  }

  select_chapter(id, chapter_name) {
    this.setState({
      chapter: id,
      chapter_text: chapter_name,
    });
    this.get_tags(id, "Subtopics");
  }

  select_teacher(id, teacher_name) {
    this.setState({
      teacher: id,
      teacher_text: teacher_name,
    });
  }

select_teacher3 (id, teacher_name) {
    this.setState({
      reason_teacher_id: id,
      teacher_text: teacher_name
    })
  }
  
  select_studio(id, studio_name) {
    this.setState({
      studio: id,
      studio_text: studio_name,
    });
  }

  select_status(id, status_name) {
    this.setState({
      status: id,
      status_text: status_name,
    });
  }

  delete_popup(id) {
    this.setState({
      id: id,
      popup_dialog4: true,
    });
  }

  delete_class() {
    const data = { 'id':this.state.id,'reason':this.state.reason ,'reason_center_id':this.state.reason_center_id};
    (async () => {
      const rawResponse = await fetch(Config.SERVER_URL + "/delete-class", {
        method: "POST",
        body: JSON.stringify(data),
        dataType: "json",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + this.state.token,
        },
      });
      const response = await rawResponse.json();
      if (response.status) {
        alert(response.msg);
        window.location.reload();
      } else {
        alert(response.msg);
      }
    })();
  }

  async select_group2(id, group_name) {
    this.setState({
      group: id,
      group_text: group_name,
    });
  }
  

  async select_subject2(id, subject_name) {
    this.setState({
      subject: id,
      subject_text: subject_name,
    });
  }

  async select_studio2(id, studio_name) {
    this.setState({
      studio: id,
      studio_text: studio_name,
    });
  }

  async select_teacher2(id, teacher_name) {
    this.setState({
      teacher: id,
      teacher_text: teacher_name,
    });
  }

  async select_status2(id, status_name) {
    this.setState({
      status: id,
      status_text: status_name,
    });
    let url = "/get-time-table";
    if (id != 0) {
      url = "/get-time-table?status_id=" + id;
    }

    const rawResponse = await fetch(Config.SERVER_URL + url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + this.state.token,
      },
    });
    const response = await rawResponse.json();
    this.setState({
      json_list: response.data,
      isLoaded: false,
    });
  }

  async get_chapters(id, chapter_name) {
    this.setState({
      chapter_text: chapter_name,
    });
    let url = "/chapters/" + id + "/";

    const rawResponse = await fetch(Config.SERVER_URL + url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + this.state.token,
      },
    });
    const response = await rawResponse.json();
    this.setState({
      chapter_list: response.data,
    });
    for (const s of response.data) {
      if (s.id == this.state.chapter) {
        this.setState({
          chapter_text: s.chapter_name,
        });
      }
    }
  }

  async get_tags(id, tag_name) {
    this.setState({
      tag_text: tag_name,
    });
    let url = "/tags/" + id + "/";

    const rawResponse = await fetch(Config.SERVER_URL + url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + this.state.token,
      },
    });
    const response = await rawResponse.json();
    this.setState({
      tag_list: response.data,
    });

    let selected_tag_lists = [];
    for (const s of response.data) {
      /*
	  if (this.state.tags.includes(s.id)) {
        selected_tag_lists.push({ id: s.id, tag_name: s.tag_name });
      }
	  */
    }
    this.setState({
      selected_tag_list: selected_tag_lists,
    });
  }

  edit_timetable(id) {
    (async () => {
      const rawResponse = await fetch(
        Config.SERVER_URL + "/get-timetable-info?id=" + id,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Token " + this.state.token,
          },
        }
      );
      const response = await rawResponse.json();
      this.setState({
        timetable_info: response.data,
        id: response.data.id,
        start_time: response.data.start_time,
        end_time: response.data.end_time,
        duration: response.data.duration,
        center: response.data.center_id,
        group: response.data.group_id,
        subject: response.data.subject_id,
        chapter: response.data.chapter_id,
        teacher: response.data.teacher_id,
        studio: response.data.studio_id,
        tags: response.data.tags,
        popup_dialog2: true,
      });

      for (const s of this.state.center_available) {
        if (s.id == response.data.center_id) {
          this.setState({
            center_text: s.name,
          });
        }
      }
      for (const s of this.state.subject_available) {
        if (s.id == response.data.subject_id) {
          this.setState({
            subject_text: s.subject_name,
          });
        }
      }
      this.get_chapters(response.data.subject_id, "Chapter");
      this.get_tags(response.data.chapter_id, "Subtopics");
      for (const s of this.state.group_available) {
        if (s.id == response.data.group_id) {
          this.setState({
            group_text: s.name,
          });
        }
      }

      for (const s of this.state.teacher_available) {
        if (s.id == response.data.teacher_id) {
          this.setState({
            teacher_text: s.teacher_name,
          });
        }
      }

      for (const s of this.state.studio_available) {
        if (s.id == response.data.studio_id) {
          this.setState({
            studio_text: s.studio_name,
          });
        }
      }
    })();
  }
  onSubmitHandler(e) {
    e.preventDefault();

    this.setState({ isLoaded2: true });
    const data = {
	  schedule_type_id: this.state.schedule_type_id,
      confliction_id: this.state.confliction_id,
      title: this.state.topic,
      desc: this.state.desc,
      start_time: this.state.start_time,
      end_time: this.state.end_time,
      duration: this.state.duration,
      studio: this.state.studio,
      center: this.state.center,
      teacher: this.state.teacher,
      group: this.state.group,
      subject: this.state.subject,
      chapter: this.state.chapter,
      selected_tags: this.state.selected_tag_list,
	  class_type: this.state.class_type_id,
    };
    (async () => {
      const rawResponse = await fetch(Config.SERVER_URL + "/create-timetable", {
        method: "POST",
        body: JSON.stringify(data),
        dataType: "json",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + this.state.token,
        },
      });
      const response = await rawResponse.json();
      this.setState({ isLoaded2: false });
      if (response.status) {
        alert(response.msg);
        window.location.reload();
      } else {
        alert(response.msg);
      }
    })();
  }

  onSubmitHandler2(e) {
    e.preventDefault();
    this.setState({ isLoaded2: true });
    const data = {
      id: this.state.id,
      confliction_id: this.state.confliction_id,
      title: this.state.topic,
      desc: this.state.desc,
      start_time: this.state.start_time,
      end_time: this.state.end_time,
      duration: this.state.duration,
      studio: this.state.studio,
      center: this.state.center,
      teacher: this.state.teacher,
      group: this.state.group,
      subject: this.state.subject,
      chapter: this.state.chapter,
      selected_tags: this.state.selected_tag_list,
    };
    (async () => {
      const rawResponse = await fetch(Config.SERVER_URL + "/save-timetable", {
        method: "POST",
        body: JSON.stringify(data),
        dataType: "json",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + this.state.token,
        },
      });
      const response = await rawResponse.json();
      this.setState({ isLoaded2: false });
      if (response.status) {
        alert(response.msg);
        window.location.reload();
      } else {
        alert(response.msg);
      }
    })();
  }
  schedule_time(id) {
    const data = { id: id };
    (async () => {
      const rawResponse = await fetch(Config.SERVER_URL + "/create-meeting", {
        method: "POST",
        body: JSON.stringify(data),
        dataType: "json",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + this.state.token,
        },
      });
      const response = await rawResponse.json();
      if (response.status) {
        alert(response.msg);
        window.location.reload();
      }
    })();
  }

  view_timetable(id) {
    (async () => {
      const rawResponse = await fetch(
        Config.SERVER_URL + "/get-timetable-info?id=" + id,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Token " + this.state.token,
          },
        }
      );
      const response = await rawResponse.json();
      this.setState({
        timetable_info: response.data,
        id: response.data.id,
        class_start_time: response.data.start_time,
        class_end_time: response.data.end_time,
        class_duration: response.data.duration,
        center_name: response.data.center_name,
        studio_name: response.data.studio_name,
        zoom_record_url: response.data.zoom_record_url,
        join_url: response.data.join_url,
        group_name: response.data.group_name,
        subject_name: response.data.subject_name,
        chapter_name: response.data.chapter_name,
        teacher_name: response.data.teacher_name,
        tags_detail: response.data.tags,
        popup_dialog3: true,
      });
    })();
  }
  componentDidMount() {
    (async () => {
      const rawResponse = await fetch(Config.SERVER_URL + "/studios/", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + this.state.token,
        },
      });
      const content = await rawResponse.json();

      this.setState({
        studio_available: content,
      });
    })();

    (async () => {
      const rawResponse = await fetch(Config.SERVER_URL + "/get-center/", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + this.state.token,
        },
      });
      const content = await rawResponse.json();

      this.setState({
        center_available: content.data,
      });
    })();

    (async () => {
      const rawResponse = await fetch(Config.SERVER_URL + "/group-list/", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + this.state.token,
        },
      });
      const content = await rawResponse.json();

      this.setState({
        group_available: content.data,
      });
    })();

    (async () => {
      // console.log(this.state.token);
      const rawResponse = await fetch(Config.SERVER_URL + "/get-subjects/", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + this.state.token,
        },
        // body: JSON.stringify(data)
      });
      const content = await rawResponse.json();

      this.setState({
        subject_available: content.data,
      });
    })();

    (async () => {
      const rawResponse = await fetch(Config.SERVER_URL + "/get-teachers/", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + this.state.token,
        },
      });
      const content = await rawResponse.json();

      this.setState({
        teacher_available: content.data,
      });
    })();

    (async () => {
      const rawResponse = await fetch(
        Config.SERVER_URL + "/get-class-status/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Token " + this.state.token,
          },
        }
      );
      const content = await rawResponse.json();

      this.setState({
        status_available: content.data,
      });
    })();

    (async () => {
      const rawResponse = await fetch(Config.SERVER_URL + "/get-time-table", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + this.state.token,
        },
      });
      const response = await rawResponse.json();
      this.setState({
        json_list: response.data,
      });
      this.setState({
        isLoaded: false,
      });
    })();
  }
  
  select_delete(){
	  var inputs = document.querySelectorAll("input.id-chk");
	   let selected_id_lists = [];
for(var i = 0; i < inputs.length; i++) {
	if(inputs[i].checked){
   let class_id = inputs[i].value;
   const data = {'id':class_id,'reason':this.state.reason ,'reason_center_id':this.state.reason_center_id};
	(async () => {
      const rawResponse = await fetch(Config.SERVER_URL + "/delete-class", {
        method: "POST",
        body: JSON.stringify(data),
        dataType: "json",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + this.state.token,
        },
      });
	})();
	
}

}
alert("Deleted")
window.location.reload();
return false;
}

  render() {
    if (this.state.login_status === false) {
      return <Redirect to="/" />;
    }

    return (
      <div>
        <Header />
        {this.state.popup_dialog && (
          <div className="modal-dialogs modal-lg" id="dialog-modals">
            <form
              className="login100 validate-form"
              id="frmTimeTable"
              method="POST"
              onSubmit={this.onSubmitHandler}
            >
              <h4 className="login100-title p-b-40">Create Time-Table</h4>
              <div
                className="wrap-input100 validate-input m-b-16"
                data-validate="Please enter Start Time"
              >
                <input
                  type="datetime-local"
                  name="start_time"
                  className="input100"
                  id="usericon"
                  placeholder="Start Time"
                  value={this.state.start_time}
                  onChange={this.onChangeHandler}
                />
                <span className="focus-input100" />
              </div>

              <div
                className="wrap-input100 validate-input m-b-20"
                data-validate="Please enter Duration"
              >
                <span className="btn-show-pass">
                  <i className="fa fa fa-eye" />
                </span>
                <input
                  type="text"
                  name="duration"
                  className="input100"
                  placeholder="Duration (in minutes)"
                  value={this.state.duration}
                  onChange={this.onChangeHandler}
                />
                <span className="focus-input100" />
              </div>

              <div
                className="wrap-input100 validate-input m-b-16 dropdown"
                data-validate="Subject"
              >
                <button
                  className="batch100-form-btn dropdown-toggle"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {" "}
                  {this.state.subject_text}
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <option
                    value="0"
                    className="dropdown-item"
                    onClick={() => this.select_subject(0, "Subject")}
                    role="button"
                  >
                    Subject
                  </option>
                  {this.state.subject_available.map((item, i) => (
                    <option
                      value="{item.subject_name}"
                      className="dropdown-item"
                      onClick={() =>
                        this.select_subject(item.id, item.subject_name)
                      }
                      role="button"
                    >
                      {item.subject_name}
                    </option>
                  ))}
                </div>
                <span className="focus-input100" />
              </div>

              <div
                className="wrap-input100 validate-input m-b-16 dropdown"
                data-validate="Please Chapter"
              >
                <button
                  className="batch100-form-btn dropdown-toggle"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {" "}
                  {this.state.chapter_text}
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <option
                    value="0"
                    className="dropdown-item"
                    onClick={() => this.select_chapter(0, "Chapter")}
                    role="button"
                  >
                    Chapter
                  </option>
                  {this.state.chapter_list.map((item, i) => (
                    <option
                      value="{item.chapter_name}"
                      className="dropdown-item"
                      onClick={() =>
                        this.select_chapter(item.id, item.chapter_name)
                      }
                      role="button"
                    >
                      {item.chapter_name}
                    </option>
                  ))}
                </div>
                <span className="focus-input100" />
              </div>

              <div
                className="wrap-input100 validate-input m-b-16 dropdown"
                data-validate="Subtopics"
              >
                <Multiselect
                  options={this.state.tag_list}
                  closeIcon="close"
                  displayValue="tag_name"
                  selectedValues={this.state.selected_tag_list}
                  onSelect={this.onSelect}
                  onRemove={this.onRemove}
                  selectionLimit="5"
                  avoidHighlightFirstOption
                  name="tagList"
                />
                <span className="focus-input100" />
              </div>

              <div
                className="wrap-input100 validate-input m-b-16 dropdown"
                data-validate="Please Teacher"
              >
                <button
                  className="batch100-form-btn dropdown-toggle"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {" "}
                  {this.state.teacher_text}
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <option
                    value="0"
                    className="dropdown-item"
                    onClick={() => this.select_teacher(0, "Faculty")}
                    role="button"
                  >
                    Faculty
                  </option>
                  {this.state.teacher_available.map((item, i) => (
                    <option
                      value="{item.teacher_name}"
                      className="dropdown-item"
                      onClick={() =>
                        this.select_teacher(item.id, item.teacher_name)
                      }
                      role="button"
                    >
                      {item.teacher_name}
                    </option>
                  ))}
                </div>
                <span className="focus-input100" />
              </div>

              <div
                className="wrap-input100 validate-input m-b-16 dropdown"
                data-validate="Please Group"
              >
                <button
                  className="batch100-form-btn dropdown-toggle"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {" "}
                  {this.state.group_text}
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <option
                    value="0"
                    className="dropdown-item"
                    onClick={() => this.select_group(0, "Group")}
                    role="button"
                  >
                    Group
                  </option>
                  {this.state.group_available.map((item, i) => (
                    <option
                      value="{item.name}"
                      className="dropdown-item"
                      role="button"
                      onClick={() => this.select_group(item.id, item.name)}
                    >
                      {item.name}
                    </option>
                  ))}
                </div>

                <span className="focus-input100" />
              </div>


              <div style={{border: '0.5px solid black', padding: '10px', paddingRight: '0'}}>
                    <span style={{marginLeft: '25px'}}>
                      Class Location
                    </span>

                    <div
                className="wrap-input100 validate-input m-b-16 dropdown"
                data-validate="Please Mode"
              >
                <button
                  className="batch100-form-btn dropdown-toggle"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {" "}
                  {this.state.schedule_type_text}
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <option
                    value="0"
                    className="dropdown-item"
                    onClick={() => this.select_schedule_type(0, "Online")}
                    role="button"
                  >
                    Online
                  </option>
                  <option
                    value="0"
                    className="dropdown-item"
                    onClick={() => this.select_schedule_type(1, "Offline")}
                    role="button"
                  >
                    Offline
                  </option>
                </div>

                <span className="focus-input100" />
              </div>

              {this.state.schedule_type_id == 0 && (
                <div
                  className="wrap-input100 validate-input m-b-16 dropdown"
                  data-validate="Please Studio"
                >
                  <button
                    className="batch100-form-btn dropdown-toggle"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {" "}
                    {this.state.studio_text}
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <option
                      value="0"
                      className="dropdown-item"
                      onClick={() => this.select_studio(0, "Studio")}
                      role="button"
                    >
                      Studio
                    </option>
                    {this.state.studio_available.map((item, i) => (
                      <option
                        value="{item.studio_name}"
                        className="dropdown-item"
                        onClick={() =>
                          this.select_studio(item.id, item.studio_name)
                        }
                        role="button"
                      >
                        {item.studio_name}
                      </option>
                    ))}
                  </div>
                  <span className="focus-input100" />
                </div>
              )}

              {this.state.schedule_type_id == 1 && (
                <div
                  className="wrap-input100 validate-input m-b-16 dropdown endtime-div"
                  data-validate="Please select center"
                >
                  <button
                    className="batch100-form-btn dropdown-toggle"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {" "}
                    {this.state.center_text}
                  </button>

                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <option
                      value="0"
                      className="dropdown-item"
                      onClick={() => this.select_center(0, "Center")}
                      role="button"
                    >
                      Select Center
                    </option>
                    {this.state.center_available.map((item, i) => (
                      <option
                        value="{item.name}"
                        className="dropdown-item"
                        onClick={() => this.select_center(item.id, item.name)}
                        role="button"
                      >
                        {item.name}
                      </option>
                    ))}
                  </div>
                  <span className="focus-input100" />
                </div>
              )}
              </div>
              




              <div
                className="wrap-input100 validate-input m-b-16 dropdown"
                data-validate="Class Tag"
              >
                <button
                  className="batch100-form-btn dropdown-toggle"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {" "}
                  {this.state.class_type_text}
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <option
                    value="0"
                    className="dropdown-item"
                    onClick={() => this.select_class_type(1, "Lecture")}
                    role="button"
                  >
                    Lecture
                  </option>
                  <option
                    value="0"
                    className="dropdown-item"
                    onClick={() => this.select_class_type(2, "Tutorial")}
                    role="button"
                  >
                    Tutorial
                  </option>
                  <option
                    value="0"
                    className="dropdown-item"
                    onClick={() => this.select_class_type(3, "Doubt")}
                    role="button"
                  >
                    Doubt
                  </option>
                </div>

                <span className="focus-input100" />
              </div>

              <div
                className="wrap-input100 validate-input m-b-16 endtime-div"
                data-validate="Please enter End Time"
              >
                <input
                  type="datetime-local"
                  name="end_time"
                  className="input100"
                  id="end_time"
                  placeholder="Start Time"
                  value={this.state.end_time}
                  onChange={this.onChangeHandler}
                />
                <span className="focus-input100" />
              </div>

              <div></div>

              <div className="container-login100-form-btn">
                <button className="login100-form-btn create-btn" type="submit">
                  Create
                </button>
                <button
                  className="login100-form-btn"
                  onClick={() => this.hide_dialog_modal()}
                >
                  Close
                </button>
                {this.state.isLoaded2 ? (
                  <ReactLoading type="cylon" color="#09AEE5" />
                ) : (
                  ""
                )}
              </div>
            </form>
          </div>
        )}

        {/* {this.state.popup_dialog2 && ()} */}

        {this.state.popup_dialog3 && (
          <div className="modal-dialogs" id="dialog-modals">
            <h4 className="login100-title p-b-40">Class Detail</h4>
            <table width="100%" border="1">
              <tr>
                <td>
                  <strong>Studio</strong>
                </td>
                <td>{this.state.studio_name}</td>
              </tr>
              <tr>
                <td>
                  <strong>Group</strong>
                </td>
                <td>{this.state.group_name}</td>
              </tr>
              <tr>
                <td>
                  <strong>Teacher</strong>
                </td>
                <td>{this.state.teacher_name}</td>
              </tr>
              <tr>
                <td>
                  <strong>Subject</strong>
                </td>
                <td>{this.state.subject_name}</td>
              </tr>
              <tr>
                <td>
                  <strong>Chapter</strong>
                </td>
                <td>{this.state.chapter_name}</td>
              </tr>
              <tr>
                <td>
                  <strong>Class Time</strong>
                </td>
                <td>
                  {Moment(this.state.class_start_time).format("DD/MM/YYYY")}
                  <br />
                  {Moment(this.state.class_start_time).format("HH:mm")}-
                  {Moment(this.state.class_end_time).format("HH:mm")}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Class Duration</strong>
                </td>
                <td>{this.state.class_duration}</td>
              </tr>
              <tr>
                <td>
                  <strong>Join Url</strong>
                </td>
                <td>{this.state.join_url}</td>
              </tr>
              <tr>
                <td>
                  <strong>Recording File</strong>
                </td>
                <td>{this.state.zoom_record_url}</td>
              </tr>
            </table>

            <div className="container-login100-form-btn">
              <button
                className="login100-form-btn"
                onClick={() => this.hide_dialog_modal()}
              >
                Close
              </button>
            </div>
          </div>
        )}

        <div className="container scheduler">
          <div className="row">
            <div className="col-md-12">
              <table width="100%">
                <thead>
                  <tr>
                    <td>
                      <a
                        href="javascript:void(0)"
                        onClick={() => this.show_dialog_modal()}
                      >
                        Create Schedule
                      </a>
                    </td>
                    <td>
                      <Link to="/conflict-timetable" className="nav-link">
                        Confliction Time-Table
                      </Link>
                    </td>
                    <td>
                      <Link to="/import-timetable" className="nav-link">
                        Import Time-Table
                      </Link>
                    </td>
                    <td>
                      <Link to="/trash-timetable" className="nav-link">
                        Trash Time-Table
                      </Link>
                    </td>
                  </tr>
                </thead>
              </table>
              {this.state.isLoaded ? (
                <ReactLoading type="cylon" color="#09AEE5" />
              ) : (
                ""
              )}
              <table width="100%">
                <thead>
                  <th colspan="2">
                    <input
                      type="date"
                      name="from_date"
                      className="input100"
                      id="usericon"
                      placeholder="From Date"
                      value={this.state.from_date}
                      onChange={this.onChangeHandlerFromDate}
                    />
                  </th>
                  <th colspan="2">
                    <input
                      type="date"
                      name="to_date"
                      className="input100"
                      id="usericon"
                      placeholder="To Date"
                      value={this.state.to_date}
                      onChange={this.onChangeHandlerToDate}
                    />
                  </th>
                  <th colspan="2">
                    <button
                      className="batch100-form-btn dropdown-toggle"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {" "}
                      {this.state.group_text}
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <option
                        value="0"
                        className="dropdown-item"
                        onClick={() => this.select_group2(0, "Group")}
                        role="button"
                      >
                        All
                      </option>
                      {this.state.group_available.map((item, i) => (
                        <option
                          value="{item.id}"
                          className="dropdown-item"
                          role="button"
                          onClick={() => this.select_group2(item.id, item.name)}
                        >
                          {item.name}
                        </option>
                      ))}
                    </div>
                  </th>
                  <th>
                    {" "}
                    <button
                      className="batch100-form-btn dropdown-toggle"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {" "}
                      {this.state.subject_text}
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <option
                        value="0"
                        className="dropdown-item"
                        onClick={() => this.select_subject2(0, "Subject")}
                        role="button"
                      >
                        All
                      </option>
                      {this.state.subject_available.map((item, i) => (
                        <option
                          value="{item.id}"
                          className="dropdown-item"
                          onClick={() =>
                            this.select_subject2(item.id, item.subject_name)
                          }
                          role="button"
                        >
                          {item.subject_name}
                        </option>
                      ))}
                    </div>
                  </th>
                  <th colspan="2">
                    <button
                      className="batch100-form-btn dropdown-toggle"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {" "}
                      {this.state.teacher_text}
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <option
                        value="0"
                        className="dropdown-item"
                        onClick={() => this.select_teacher2(0, "Teacher")}
                        role="button"
                      >
                        All
                      </option>
                      {this.state.teacher_available.map((item, i) => (
                        <option
                          value="{item.id}"
                          className="dropdown-item"
                          onClick={() =>
                            this.select_teacher2(item.id, item.teacher_name)
                          }
                          role="button"
                        >
                          {item.teacher_name}
                        </option>
                      ))}
                    </div>
                  </th>
                  <th>
                    <button
                      className="batch100-form-btn dropdown-toggle"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {" "}
                      {this.state.studio_text}
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <option
                        value="0"
                        className="dropdown-item"
                        onClick={() => this.select_studio2(0, "Studio")}
                        role="button"
                      >
                        All
                      </option>
                      {this.state.studio_available.map((item, i) => (
                        <option
                          value="{item.id}"
                          className="dropdown-item"
                          onClick={() =>
                            this.select_studio2(item.id, item.studio_name)
                          }
                          role="button"
                        >
                          {item.studio_name}
                        </option>
                      ))}
                    </div>
                  </th>
				  <th>
				
                <button
                  className="batch100-form-btn dropdown-toggle"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {" "}
                  {this.state.schedule_type_text2}
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <option
                    value="0"
                    className="dropdown-item"
                    onClick={() => this.select_schedule_type2(0, "Online")}
                    role="button"
                  >
                    Online
                  </option>
                  <option
                    value="0"
                    className="dropdown-item"
                    onClick={() => this.select_schedule_type2(1, "Offline")}
                    role="button"
                  >
                    Offline
                  </option>
                </div>

				  </th>
				  <th>
				 
                <button
                  className="batch100-form-btn dropdown-toggle"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {" "}
                  {this.state.class_type_text}
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  
                  <option
                    value="0"
                    className="dropdown-item"
                    onClick={() => this.select_class_type(1, "Lecture")}
                    role="button"
                  >
                    Lecture
                  </option>
				  <option
                    value="0"
                    className="dropdown-item"
                    onClick={() => this.select_class_type(2, "Tutorial")}
                    role="button"
                  >
                    Tutorial
                  </option>
				  <option
                    value="0"
                    className="dropdown-item"
                    onClick={() => this.select_class_type(3, "Doubt")}
                    role="button"
                  >
                    Doubt
                  </option>
				 </div>
			  </th>
				  
                  <th>
                    <button
                      className="batch100-form-btn dropdown-toggle"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {" "}
                      {this.state.status_text}
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <option
                        value="0"
                        className="dropdown-item"
                        onClick={() => this.select_status2(0, "Status")}
                        role="button"
                      >
                        All
                      </option>
                      {this.state.status_available.map((item, i) => (
                        <option
                          value="{item.id}"
                          className="dropdown-item"
                          onClick={() =>
                            this.select_status2(item.id, item.status_name)
                          }
                          role="button"
                        >
                          {item.status_name}
                        </option>
                      ))}
                    </div>
                  </th>
                  <th>
                    <button
                      className="login100-form-btn"
                      type="submit"
                      onClick={this.onSearchHandler}
                    >
                      Search
                    </button>
                  </th>
                </thead>
                <thead>
				<th colSpan="1"><button name="btnDelete" id="btnDelete" data-toggle="modal" data-target="#deleteModal2" >Delete</button></th>
                  <th colSpan="4">
                    <input
                      type="radio"
                      name="order_by"
                      onClick={() => this.select_order("start_time", "")}
                    />{" "}
                    Ascending &nbsp;{" "}
                    <input
                      type="radio"
                      name="order_by"
                      onClick={() => this.select_order("start_time", "-")}
                    />{" "}
                    Descending
                  </th>
				  
                </thead>
                <thead>
                  <th colspan="1">Id</th>
				  <th colspan="2">Date</th>
                  <th colspan="2">Time</th>
                  <th colspan="2">Group</th>
                  <th>Subject</th>
                  <th>Teacher</th>
                  <th colspan="2">Studio</th>
                  <th>Status</th>
                  <th>Action</th>
                </thead>
                <tbody>
                  {this.state.json_list.map((data, key) => {
                    return (
                      <tr>
				  <td><input type="checkbox" className="id-chk" value={data.id} /> {data.live_class_idy}</td>
                        <td colspan="2">
                          {Moment(data.start_time).format("DD/MM/YYYY")}
                        </td>
                        <td colspan="2">
                          {Moment(data.start_time).format("HH:mm")}-
                          {Moment(data.end_time).format("HH:mm")}
                        </td>
                        <td colspan="2">{data.group_name}</td>
                        <td>{data.subject_name}</td>
                        <td colspan="2">{data.teacher_name}</td>
                        <td>{data.studio_name}</td>
						<td></td>
                        <td>{data.class_status}</td>
                        <td>
                          <button onClick={() => this.view_timetable(data.id)}>
                            View
                          </button>
                          |
                          <button
                            onClick={() => this.edit_timetable(data.id)}
                            data-toggle="modal"
                            data-target="#exampleModal"
                          >
                            Edit
                          </button>
                          |
                          <button onClick={() => this.delete_popup(data.id)} data-toggle="modal" data-target="#deleteModal" >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body p-3">
                <form
                  className="login100 validate-form"
                  id="frmTimeTable"
                  method="POST"
                  onSubmit={this.onSubmitHandler2}
                >
                  <h4 className="login100-title p-b-40">Edit Time-Table</h4>

                  <div
                    className="wrap-input100 validate-input m-b-16 dropdown"
                    data-validate="Please Group"
                  >
                    <button
                      className="batch100-form-btn dropdown-toggle"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {" "}
                      {this.state.group_text}
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <option
                        value="0"
                        className="dropdown-item"
                        onClick={() => this.select_group(0, "Group")}
                        role="button"
                      >
                        Group
                      </option>
                      {this.state.group_available.map((item, i) => (
                        <option
                          value="{item.id}"
                          className="dropdown-item"
                          role="button"
                          onClick={() => this.select_group(item.id, item.name)}
                        >
                          {item.name}
                        </option>
                      ))}
                    </div>

                    <span className="focus-input100" />
                  </div>

                  <div
                    className="wrap-input100 validate-input m-b-16 dropdown endtime-div"
                    data-validate="Please select center"
                  >
                    <button
                      className="batch100-form-btn dropdown-toggle"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {" "}
                      {this.state.center_text}
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <option
                        value="0"
                        className="dropdown-item"
                        onClick={() => this.select_center(0, "Subject")}
                        role="button"
                      >
                        Select Center
                      </option>
                      {this.state.center_available.map((item, i) => (
                        <option
                          value="{item.name}"
                          className="dropdown-item"
                          onClick={() => this.select_center(item.id, item.name)}
                          role="button"
                        >
                          {item.name}
                        </option>
                      ))}
                    </div>
                    <span className="focus-input100" />
                  </div>

                  <div
                    className="wrap-input100 validate-input m-b-16 dropdown"
                    data-validate="Please Subject"
                  >
                    <button
                      className="batch100-form-btn dropdown-toggle"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {" "}
                      {this.state.subject_text}
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <option
                        value="0"
                        className="dropdown-item"
                        onClick={() => this.select_subject(0, "Subject")}
                        role="button"
                      >
                        Subject
                      </option>
                      {this.state.subject_available.map((item, i) => (
                        <option
                          value="{item.subject_name}"
                          className="dropdown-item"
                          onClick={() =>
                            this.select_subject(item.id, item.subject_name)
                          }
                          role="button"
                        >
                          {item.subject_name}
                        </option>
                      ))}
                    </div>
                    <span className="focus-input100" />
                  </div>

                  <div
                    className="wrap-input100 validate-input m-b-16 dropdown"
                    data-validate="Please Chapter"
                  >
                    <button
                      className="batch100-form-btn dropdown-toggle"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {" "}
                      {this.state.chapter_text}
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <option
                        value="0"
                        className="dropdown-item"
                        onClick={() => this.select_chapter(0, "Chapter")}
                        role="button"
                      >
                        Chapter
                      </option>
                      {this.state.chapter_list.map((item, i) => (
                        <option
                          value="{item.chapter_name}"
                          className="dropdown-item"
                          onClick={() =>
                            this.select_chapter(item.id, item.chapter_name)
                          }
                          role="button"
                        >
                          {item.chapter_name}
                        </option>
                      ))}
                    </div>
                    <span className="focus-input100" />
                  </div>

                  <div
                    className="wrap-input100 validate-input m-b-16 dropdown"
                    data-validate="Please Subtopics"
                  >
                    <Multiselect
                      options={this.state.tag_list}
                      closeIcon="close"
                      displayValue="tag_name"
                      selectedValues={this.state.selected_tag_list}
                      onSelect={this.onSelect}
                      onRemove={this.onRemove}
                      selectionLimit="5"
                      avoidHighlightFirstOption
                      name="tagList"
                    />
                    <span className="focus-input100" />
                  </div>

                  <div
                    className="wrap-input100 validate-input m-b-16"
                    data-validate="Please enter Start Time"
                  >
                    <input
                      type="datetime-local"
                      name="start_time"
                      className="input100"
                      id="usericon"
                      placeholder="Start Time"
                      value={this.state.start_time}
                      onChange={this.onChangeHandler}
                    />
                    <span className="focus-input100" />
                  </div>

                  <div
                    className="wrap-input100 validate-input m-b-16 endtime-div"
                    data-validate="Please enter End Time"
                  >
                    <input
                      type="datetime-local"
                      name="end_time"
                      className="input100"
                      id="end_time"
                      placeholder="Start Time"
                      value={this.state.end_time}
                      onChange={this.onChangeHandler}
                    />
                    <span className="focus-input100" />
                  </div>

                  <div
                    className="wrap-input100 validate-input m-b-20"
                    data-validate="Please enter Duration"
                  >
                    <span className="btn-show-pass">
                      <i className="fa fa fa-eye" />
                    </span>
                    <input
                      type="text"
                      name="duration"
                      className="input100"
                      placeholder="Duration (in minutes)"
                      value={this.state.duration}
                      onChange={this.onChangeHandler}
                    />
                    <span className="focus-input100" />
                  </div>

                  <div
                    className="wrap-input100 validate-input m-b-16 dropdown"
                    data-validate="Please Studio"
                  >
                    <button
                      className="batch100-form-btn dropdown-toggle"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {" "}
                      {this.state.studio_text}
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <option
                        value="0"
                        className="dropdown-item"
                        onClick={() => this.select_studio(0, "Studio")}
                        role="button"
                      >
                        Studio
                      </option>
                      {this.state.studio_available.map((item, i) => (
                        <option
                          value="{item.id}"
                          className="dropdown-item"
                          onClick={() =>
                            this.select_studio(item.id, item.studio_name)
                          }
                          role="button"
                        >
                          {item.studio_name}
                        </option>
                      ))}
                    </div>
                    <span className="focus-input100" />
                  </div>

                  <div
                    className="wrap-input100 validate-input m-b-16 dropdown"
                    data-validate="Please Teacher"
                  >
                    <button
                      className="batch100-form-btn dropdown-toggle"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {" "}
                      {this.state.teacher_text}
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <option
                        value="0"
                        className="dropdown-item"
                        onClick={() => this.select_teacher(0, "Teacher")}
                        role="button"
                      >
                        Teacher
                      </option>
                      {this.state.teacher_available.map((item, i) => (
                        <option
                          value="{item.id}"
                          className="dropdown-item"
                          onClick={() =>
                            this.select_teacher(item.id, item.teacher_name)
                          }
                          role="button"
                        >
                          {item.teacher_name}
                        </option>
                      ))}
                    </div>
                    <span className="focus-input100" />
                  </div>

                  <div className="container-login100-form-btn">
                    <button className="login100-form-btn" type="submit">
                      Update
                    </button>
                    <button
                      className="login100-form-btn"
                      data-dismiss="modal"
                      onClick={() => this.hide_dialog_modal()}
                    >
                      Close
                    </button>
                    {this.state.isLoaded2 ? (
                      <ReactLoading type="cylon" color="#09AEE5" />
                    ) : (
                      ""
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="deleteModal"
          tabindex="-1"
          role="dialog"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body p-3">
                <form className='login100 validate-form' id="frmDeleteTimeTable">
                    <h4 className='login100-title p-b-40'>
																						Delete Class
                    </h4>
					
					<div className='wrap-input100 validate-input m-b-16 dropdown' data-validate='Select Reason'>
                      <button className='batch100-form-btn dropdown-toggle' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>{this.state.reason_text}</button>
                      <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                       <option className='dropdown-item' onClick={() => this.select_reason('Scheduling Error')} role='button'>Scheduling Error</option>
					   <option className='dropdown-item' onClick={() => this.select_reason('Faculty Not Available')} role='button'>Faculty Not Available</option>
					   <option className='dropdown-item' onClick={() => this.select_reason('Center Request')} role='button'>Center Request</option>
					   <option className='dropdown-item' onClick={() => this.select_reason('School/Other National Exams')} role='button'>School/Other National Exams</option>
					   <option className='dropdown-item' onClick={() => this.select_reason('For Testing')} role='button'>For Testing</option>
                      </div>
                      <span className='focus-input100' />
                    </div>
					
					{this.state.reason == 'Center Request' && ( 
					<div className='wrap-input100 validate-input m-b-16 dropdown' data-validate='Please select center'>
                      <button className='batch100-form-btn dropdown-toggle' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'> {this.state.center_text}</button>
                      <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
					  <option value='0' className='dropdown-item' onClick={() => this.select_center3(0, 'Center')} role='button'>Select Center</option>
                        {this.state.center_available.map((item, i) => (
                          <option value='{item.name}' className='dropdown-item' onClick={() => this.select_center3(item.id, item.name)} role='button'>{item.name}</option>
                        ))}

                      </div>
                      <span className='focus-input100' />
                    </div>
					)}
                    <div className='container-login100-form-btn'>
                      <button className='login100-form-btn' onClick={() => this.delete_class()}>Delete</button>
					  <button className='login100-form-btn' data-dismiss="modal" onClick={() => this.hide_dialog_modal()}>Close</button>
                    
					  {this.state.isLoaded2 ? <ReactLoading type="cylon" color="#09AEE5" /> : ''}
                    </div>

                  </form>
              </div>
            </div>
          </div>
        </div>
		
		
		
		
		<div
          className="modal fade"
          id="deleteModal2"
          tabindex="-1"
          role="dialog"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body p-3">
                <form className='login100 validate-form' id="frmDeleteTimeTable">
                    <h4 className='login100-title p-b-40'>
																						Delete Class
                    </h4>
					
					<div className='wrap-input100 validate-input m-b-16 dropdown' data-validate='Select Reason'>
                      <button className='batch100-form-btn dropdown-toggle' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>{this.state.reason_text}</button>
                      <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                       <option className='dropdown-item' onClick={() => this.select_reason('Scheduling Error')} role='button'>Scheduling Error</option>
					   <option className='dropdown-item' onClick={() => this.select_reason('Faculty Not Available')} role='button'>Faculty Not Available</option>
					   <option className='dropdown-item' onClick={() => this.select_reason('Center Request')} role='button'>Center Request</option>
					   <option className='dropdown-item' onClick={() => this.select_reason('School/Other National Exams')} role='button'>School/Other National Exams</option>
					   <option className='dropdown-item' onClick={() => this.select_reason('For Testing')} role='button'>For Testing</option>
                      </div>
                      <span className='focus-input100' />
                    </div>
					
					{this.state.reason == 'Center Request' && ( 
					<div className='wrap-input100 validate-input m-b-16 dropdown' data-validate='Please select center'>
                      <button className='batch100-form-btn dropdown-toggle' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'> {this.state.center_text}</button>
                      <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
					  <option value='0' className='dropdown-item' onClick={() => this.select_center3(0, 'Center')} role='button'>Select Center</option>
                        {this.state.center_available.map((item, i) => (
                          <option value='{item.name}' className='dropdown-item' onClick={() => this.select_center3(item.id, item.name)} role='button'>{item.name}</option>
                        ))}

                      </div>
                      <span className='focus-input100' />
                    </div>
					)}
                    <div className='container-login100-form-btn'>
                      <button className='login100-form-btn' onClick={() => this.select_delete()}>Delete</button>
					  <button className='login100-form-btn' data-dismiss="modal" onClick={() => this.hide_dialog_modal()}>Close</button>
                    
					  {this.state.isLoaded2 ? <ReactLoading type="cylon" color="#09AEE5" /> : ''}
                    </div>

                  </form>
              </div>
            </div>
          </div>
        </div>


      </div>
    );
  }
}

export default TimeTable;
