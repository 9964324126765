import Login from './Login';
import Logout from './Logout';
import Dashboard from './Dashboard';
import Timetable from './TimeTable';
import ImportTimeTable from './ImportTimeTable';
import ConflictTimeTable from './ConflictTimeTable';
import Scheduler from './Scheduler';
import Studio from './Studio';
import Group from './Group';
import CreateGroup from './CreateGroup';
import EditGroup from './EditGroup';
import CopyGroup from './CopyGroup'
import UnscheduleClass from './UnscheduleClass';
import ScheduleClass from './ScheduleClass';
import TeacherReport from './TeacherReport';
import StudentForm from './StudentForm';
import TrashClass from './TrashClass';
import { HashRouter as Router,BrowserRouter,Link,Switch,Route } from 'react-router-dom';

function App() {
  return (
	   	<Router>
		     <Switch>
		     	<Route exact path="/" component={Login} />
		     	<Route exact path="/dashboard" component={Dashboard} />
		     	<Route exact path="/scheduler" component={Scheduler} />
		     	<Route exact path='/timetable' component={Timetable} />
				<Route exact path='/import-timetable' component={ImportTimeTable} />
				<Route exact path='/conflict-timetable' component={ConflictTimeTable} />
				<Route exact path='/studio' component={Studio} />
				<Route exact path='/group' component={Group} />
				<Route exact path='/create-group' component={CreateGroup} />
				<Route path="/edit-group/:id" component={EditGroup}/> 
				<Route path="/copy-group/:id" component={CopyGroup}/> 
				<Route exact path='/schedule-class' component={ScheduleClass} />
				<Route exact path='/unschedule-class' component={UnscheduleClass} />
				<Route exact path='/teacher-report' component={TeacherReport} />
				<Route exact path='/trash-timetable' component={TrashClass} />
		     	<Route exact path='/form' component={StudentForm} />
		     	<Route exact path="/logout" component={Logout} />
		     </Switch>
	   </Router>
  );
}

export default App;
