import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Config from "../config.json";
import ReactDOM from "react-dom";
import { Multiselect } from "multiselect-react-dropdown";
import Modal from "react-modal";
import Moment from 'moment';
import '../static/dashboard.css'
import logo2 from '../static/ZinEdu_LOGO.svg'
import '../static/sch.css'
import Header from './Header';
Modal.setAppElement("#root");
class CopyGroup extends Component {
	
  constructor () {
    super()
    const token = localStorage.getItem('token')
	
    let login_status = true
    if (token === null) {
      login_status = false
    }
    this.state = {
      login_status,
      token,
      isLoaded: false,
	  id: null,
	  batch_ids: [],
	  batches: [],
	  selected_batch_list: [],
	  batch_list: [],
	  center_ids: [],
	  selected_center_list: [],
	  center_list: [],
	  curr_count: 0,
	  name: null,
    }
	this.onChangeHandler = this.onChangeHandler.bind(this)
	this.onSubmitHandler = this.onSubmitHandler.bind(this)
  }


  onChangeHandler (e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  
  update_batch_list(id){
	  if(document.getElementById("batch-"+id).checked){
	  if(!this.state.selected_batch_list.includes(id)){
		  let selected_batch_lists = this.state.selected_batch_list;
		  selected_batch_lists.push(id);
		  this.setState({
		  	selected_batch_list: selected_batch_lists
		  })
	  }
	  }
	  else{
		  if(this.state.selected_batch_list.includes(id)){
		  let selected_batch_lists = this.state.selected_batch_list;
		  const index = selected_batch_lists.indexOf(id);
		if (index > -1) {
		  selected_batch_lists.splice(index, 1);
		}
		  this.setState({
		  	selected_batch_list: selected_batch_lists
		  })
	  }
	  }
  }
  
  update_center_list(id){
	  if(document.getElementById("center-"+id).checked){
	  if(!this.state.selected_center_list.includes(id)){
		  let selected_center_lists = this.state.selected_center_list;
		  selected_center_lists.push(id);
		  this.setState({
		  	selected_center_list: selected_center_lists
		  })
	  }
	  }
	  else{
		  if(this.state.selected_center_list.includes(id)){
		  let selected_center_lists = this.state.selected_center_list;
		  const index = selected_center_lists.indexOf(id);
		if (index > -1) {
		  selected_center_lists.splice(index, 1);
		}
		  this.setState({
		  	selected_center_list: selected_center_lists
		  })
	  }
	  }
  }
  
  onSubmitHandler (e) {
    e.preventDefault()
     const data = { name: this.state.name, centers:this.state.selected_center_list, batches:this.state.selected_batch_list };
    (async () => {
		  const rawResponse = await fetch(Config.SERVER_URL +'/create-group', {
		    method: 'POST',
			body: JSON.stringify(data),
			dataType: 'json',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		  })
		  const response = await rawResponse.json()
		  if(response.status){
			  alert(response.msg);
			  window.location.href='/#/group'
		  }
		  else{
			  alert(response.msg);
		  }
    })()
  }
  


  componentDidMount () {
	  let group_id = this.props.match.params.id;
	  (async () => {
     
		  const rawResponse = await fetch(Config.SERVER_URL +'/group-info/'+group_id, {
		    method: 'GET',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		   
		  })
		  const content = await rawResponse.json()
		  this.setState({
		  	id: content.data.id,
			name: content.data.name,
			batches: content.data.batches,
			centers: content.data.centers,
		  })
		  let selected_batch_lists = this.state.selected_batch_list;
		  for (const s of this.state.batches){
				selected_batch_lists.push(s.batch_id);
		  }
		  this.setState({
		  	selected_batch_list: selected_batch_lists
		  });
		  let selected_center_lists = this.state.selected_center_list;
		  for (const s of this.state.centers){
				selected_center_lists.push(s.center_id);
		  }
		  this.setState({
		  	selected_center_list: selected_center_lists
		  });

		  
    })();
	
	 (async () => {
     
		  const rawResponse = await fetch(Config.SERVER_URL +'/get-center/', {
		    method: 'GET',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		   
		  })
		  const content = await rawResponse.json()
		  this.setState({
		  	center_list: content.data,
		  })
		  
    })();
	  (async () => {
     
		  const rawResponse = await fetch(Config.SERVER_URL +'/get-batch-list/', {
		    method: 'GET',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		   
		  })
		  const content = await rawResponse.json()
		  this.setState({
		  	batch_list: content.data,
		  })

		  
    })();	
  }

  
  render () {
    if (this.state.login_status === false) {
      return <Redirect to='/' />
    }
	
	
	
        
        return (
          <div>
            <Header />
                   <div class='container scheduler'>
              <div class='row'>

                  <form class='login100 validate-form' id="frmGroup" method='POST' onSubmit={this.onSubmitHandler}>
                    <h4 class='login100-form-title p-b-40'>
																						Copy Group
                    </h4>
					 <div class='wrap-input100 validate-input m-b-20' data-validate='Please enter Group Name'>
                      <span class='btn-show-pass'>
                        <i class='fa fa fa-eye' />
                      </span>
                      <input type='text' name='name' class='input100' placeholder='Group Name' value={this.state.name} onChange={this.onChangeHandler}  />
                      <span class='focus-input100' />
                    </div>
					
					<div class="batch-list2">
					<div class='row'>
					<div class='col-md-12'><h3>Centers</h3></div>
					</div>
					<div class='row batch-box'>
					{this.state.center_list.map((item, k) => {
						var checked = this.state.selected_center_list.includes(item.id);
						return (	
					<div class='col-md-3'><input type="checkbox" id={"center-" + item.id} onClick={() => this.update_center_list(item.id)} checked={checked ? 'checked' : ''} /> {item.name}</div>

					);})}
					</div>
					</div>
					
					<div class="batch-list">
					{this.state.batch_list.map((data, key) => { return (
					<div>
					<div class='row'>
					<div class='col-md-12'><h3>{data.class_name}</h3></div>
					</div>
					<div class='row batch-box'>
					{data.batch.map((item, k) => {
						var checked = this.state.selected_batch_list.includes(item.id);

return (	
					<div class='col-md-3'><input type="checkbox" id={"batch-"+item.id} onClick={() => this.update_batch_list(item.id)} checked={checked ? 'checked' : ''} /> {item.section_name}</div>
					
					);})}
					</div>
					</div>
					);})}
					</div>
					
                    <div class='container-login100-form-btn'>
					<br />
                      <button class='login100-form-btn' type='submit'>Update</button>
                    </div>

                  </form>


</div></div>
            

          </div>
        )
  }
}

export default CopyGroup
