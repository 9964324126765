import React, {Component} from 'react';
import {Link,Redirect} from 'react-router-dom';
import '../static/timetable.css';
import logo2 from '../static/ZinEdu_LOGO.svg';
class Header extends Component{
	render(){

		return(

			<header class="header">
							    <nav class="navbar navbar-expand-lg fixed-top py-3">
							        <div class="container"><a class="navbar-brand" href="#">
							    <img src={logo2} width="200" height="50" alt="" loading="lazy" />
							  </a>
							            <button type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler navbar-toggler-right"><i class="fa fa-bars"></i></button>
							            
							            <div id="navbarSupportedContent" class="collapse navbar-collapse">
							                <ul class="navbar-nav ml-auto">
							                    <li class="nav-item active"><Link to='/dashboard' class="nav-link text-uppercase">Home </Link></li>
							                    <li class="nav-item"><Link to="/timetable" class="nav-link text-uppercase">Timetable</Link></li>
												<li class="nav-item"><Link to="/unschedule-class" class="nav-link text-uppercase">Unschduled</Link></li>
												<li class="nav-item"><Link to="/schedule-class" class="nav-link text-uppercase">Scheduled</Link></li>
												<li class="nav-item"><Link to="/studio" class="nav-link text-uppercase">Studios</Link></li>
												<li class="nav-item"><Link to="/group" class="nav-link text-uppercase">Group</Link></li>
												
												
												<li class="nav-item"><Link to='/logout' class="btn btn-primary btn-sm">Logout</Link></li>
							                </ul>
							            </div>
							        </div>
							    </nav>
							</header>

			);
		
	}
}

export default Header;