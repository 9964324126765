import React, { Component } from "react";
import Config from "../config.json";
import { Redirect } from "react-router-dom";
import banner from "../static/banner.png";
import logo from "../static/ZinEdu_LOGO.svg";
import '../static/login-style.css';

class Login extends Component{

	constructor(){
		super();
		let login_status = false;
		let token = localStorage.getItem("token");
		
		if(token != null){
			this.state = {
				username:'',
				password:'',
				token:null,
				login_status:true
			}
			
		}else{
			this.state = {
				username:'',
				password:'',
				token:null,
				login_status
			}
		}

		

		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
		// console.log(login_status);
	}

	onChangeHandler(e){
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	onSubmitHandler(e){
		e.preventDefault();
		const { username , password } = this.state;

		const data = { username: username,password: password };


		(async () => {
		  const rawResponse = await fetch(Config.SERVER_URL + '/login/', {
		    method: 'POST',
		    headers: {
		      'Accept': 'application/json',
		      'Content-Type': 'application/json'
		    },
		    body: JSON.stringify(data)
		  });
		  const content = await rawResponse.json();

		  // console.log(content["token"]);

		  if(content["token"]){

		  		this.setState({
		  			login_status:true,
		  			token:content["token"]
		  		});
			  
			}

		})();

		
		
			

	}

	renderRedirect = () =>{
		if(this.state.login_status){
				return <Redirect to="/dashboard" />
			}
	}

	render(){
		// console.log(this.state.token);
		if(this.state.token!=null){
			localStorage.setItem("token",this.state.token);
		}
		return(

			<div>
				{this.renderRedirect()}
				<div class="hero">
					    <div class= 'container'>
					    	<div class = "row">
					    		<div class="logo">
					    			<img src={logo} />
					    		</div>
					    		<div class ="col-sm-6">
					    			<div class="row">
					    				<div class="col-sm-12">
					    					<h3>WELCOME TO</h3>
					    					<h3>STUDIO MANAGEMENT</h3>
					    					<h3>SYSTEM</h3>
					    				</div>
					    			</div>
					    		</div>
					    		<div class ="col-sm-6">
					    			<center>
					    						<form class="login100-form validate-form" method="POST" onSubmit={this.onSubmitHandler}>
									<h4 class="login100-form-title p-b-40">
										LOGIN
									</h4>

									

									
									<div class="wrap-input100 validate-input m-b-16" data-validate="Please enter email: ex@abc.xyz">
										<input type="text" name="username" class="input100" id="usericon" placeholder="Username" value={this.state.username} onChange={this.onChangeHandler} />
										<span class="focus-input100"></span>
									</div>

									<div class="wrap-input100 validate-input m-b-20" data-validate = "Please enter password">
										<span class="btn-show-pass">
											<i class="fa fa fa-eye"></i>
										</span>
										<input type="password" name="password" class="input100" placeholder="Password" value={this.state.password}  onChange={this.onChangeHandler} />
										<span class="focus-input100"></span>
									</div>

									<div class="container-login100-form-btn">
										<button class="login100-form-btn" type="submit">
											Login
										</button>
									</div>
									
									
									
									
								</form>
					    			</center>
					    		</div>
					    	</div>
					    </div>
				    </div>

			</div>

			);

	}
}

export default Login;