import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Config from "../config.json";
import ReactLoading from 'react-loading';
import axios from 'axios';
import Moment from 'moment';
import '../static/dashboard.css'
import logo2 from '../static/ZinEdu_LOGO.svg'
import '../static/sch.css'
import timetblexl from '../static/Timetable.xlsx';
import Header from './Header';
class ImportTimeTable extends Component {
  constructor () {
    super()
    const token = localStorage.getItem('token')
    let login_status = true
    if (token === null) {
      login_status = false
    }
  this.state = {
    endpoint: 'https://ap.zinedu.com/',
      login_status,
      token,
      isLoaded2: false,
      isLoaded: true,
    selectedFile: null,   
  }
}
  componentDidMount () {
    
  }
  

  handleFileChange = (e) => {
    this.setState({
      excel_file: e.target.files[0]
    })
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      isLoaded2: true
    })
    let form_data = new FormData();
    form_data.append('excel_file', this.state.excel_file, this.state.excel_file.name);
    axios.post(Config.SERVER_URL + '/import-timetable', form_data, {
      headers: {
        'content-type': 'multipart/form-data',
    Authorization: 'Token ' + this.state.token
      }
    })
        .then(res => {
          this.setState({
            isLoaded2: false
          })
        if(res.data.num_conflict > 0){
        alert('Time-Table imported successfully. But '+res.data.num_conflict.toString()+' conflicts');
        window.location.href='/#/conflict-timetable'
        }       
      else{
        alert(res.data.msg);
        window.location.href='/#/timetable'
      }
        })
        .catch(err => console.log(err))
  };

  render () {
    if (this.state.login_status === false) {
      return <Redirect to='/' />
    }
        
        return (
          <div>
           <Header />
           <div class='container scheduler'>
           <form onSubmit={this.handleSubmit}>
           <div class='row'>
            <div class='login100 validate-form' id="frmTimeTable">
             <h4 class='login100-title p-b-40'>Import Time-Table</h4>
             <br />
          <br />
           <div class='row'>
          <div class='col-md-3'>
            <input type="file"

                   id="image"
                   accept=".xlsx"  onChange={this.handleFileChange} required />
          </div>
            <div class='col-md-2'><button class="btn btn-primary btn-sm" name='btnImport' onClick={this.onFileUpload}>Import</button>
            {this.state.isLoaded2 ? <ReactLoading type="cylon" color="#09AEE5" /> : ''}
            </div>
          <div class='col-md-4'><a href={timetblexl}>Download Sample</a></div>
          </div>
            </div>
           </div>
           </form>
           </div>
          </div>
        
        )
  }
}

export default ImportTimeTable
