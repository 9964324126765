import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Config from "../config.json";
import ReactDOM from "react-dom";
import { Multiselect } from "multiselect-react-dropdown";
import Modal from "react-modal";
import ReactLoading from 'react-loading';
import Moment from 'moment';
import '../static/dashboard.css'
import logo2 from '../static/ZinEdu_LOGO.svg'
import '../static/sch.css'
import Header from './Header';
Modal.setAppElement("#root");
class Group extends Component {
	
  constructor () {
    super()
    const token = localStorage.getItem('token')
	
    let login_status = true
    if (token === null) {
      login_status = false
    }
    this.state = {
      login_status,
      token,
      isLoaded: true,
	  id: null,
	  batch_ids: [],
	  selected_batch_list: [],
	  batch_list: [],
	  group_list: [],
	  group: null,
	  group_info: [],
	  name: null,
	  status:null,
      desc:null,
	  popup_dialog:false,
	  popup_dialog2: false,
    }
	this.onChangeHandler = this.onChangeHandler.bind(this)
	this.onSubmitHandler = this.onSubmitHandler.bind(this)
	this.onSubmitHandler2 = this.onSubmitHandler2.bind(this);
	this.onSelect = this.onSelect.bind(this)
	this.onRemove = this.onRemove.bind(this)
  }


  onChangeHandler (e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  
  onSubmitHandler (e) {
    e.preventDefault()
     const data = { name: this.state.name, batches:this.state.selected_batch_list };
    (async () => {
		  const rawResponse = await fetch(Config.SERVER_URL +'/create-group', {
		    method: 'POST',
			body: JSON.stringify(data),
			dataType: 'json',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		  })
		  const response = await rawResponse.json()
		  if(response.status){
			  alert(response.msg);
			 window.location.reload();
		  }
		  else{
			  alert(response.msg);
		  }
    })()
  }
  
  onSelect(selectedList, selectedItem) {
		  let selected_batch_lists = [];
		  for(const s of selectedList){
				  selected_batch_lists.push({'id':s.id,'batch_name':s.batch_name});
		  }
		  this.setState({
		  	selected_batch_list: selected_batch_lists
		  })
		  
}
onRemove(selectedList, removedItem){
	let selected_batch_lists = [];
		  for(const s of selectedList){
				  selected_batch_lists.push({'id':s.id,'batch_name':s.batch_name});
		  }
		 this.setState({
		  	selected_batch_list: selected_batch_lists
		  })
		  console.log(removedItem);
}
  
  onSubmitHandler2 (e) {
    e.preventDefault()
     const data = { id: this.state.id, name: this.state.name, batches:this.state.selected_batch_list };
    (async () => {
		  const rawResponse = await fetch(Config.SERVER_URL +'/save-group', {
		    method: 'POST',
			body: JSON.stringify(data),
			dataType: 'json',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		  })
		  const response = await rawResponse.json()
		  if(response.status){
			  alert(response.msg);
			 window.location.reload();
		  }
		  else{
			  alert(response.msg);
		  }
    })()
  }

show_dialog_modal(){
	this.setState({
      popup_dialog: true
	  })
}
hide_dialog_modal(){
	this.setState({
      popup_dialog: false,
	  popup_dialog2: false,
	  })
}

copy_group(id){
		window.location.href='/#/copy-group/'+id
}
edit_group(id){
	window.location.href='/#/edit-group/'+id
}
  componentDidMount () {
	  
	  (async () => {
     
		  const rawResponse = await fetch(Config.SERVER_URL +'/get-batch/', {
		    method: 'GET',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		   
		  })
		  const content = await rawResponse.json()
		  let batch_lists = []
			for(const s of content.data){
				  batch_lists.push({'id':s.id,'batch_name':s.section_name});
		  }
		  this.setState({
		  	batch_list: batch_lists,
		  })

		  
    })();
	
    (async () => {
     
		  const rawResponse = await fetch(Config.SERVER_URL +'/group-list/', {
		    method: 'GET',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		   
		  })
		  const content = await rawResponse.json()

		  this.setState({
		  	group_list: content.data,
		  })
		  this.setState({
		  	isLoaded: false
		  })
    })();
	
	
	
	
  }
  
  delete_group(id){
	  
	  const data = { id: id};
    (async () => {
		  const rawResponse = await fetch(Config.SERVER_URL +'/delete-group', {
		    method: 'POST',
			body: JSON.stringify(data),
			dataType: 'json',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		  })
		  const response = await rawResponse.json()
		  if(response.status){
			  alert(response.msg);
			  window.location.reload();
		  }
    })()
  }

  
  render () {
    if (this.state.login_status === false) {
      return <Redirect to='/' />
    }
	
	
	
        
        return (
          <div>
            <Header />
			{this.state.popup_dialog && ( 
			<div class='modal-dialogs' id='dialog-modals'>
                  <form class='login100 validate-form' id="frmStudio" method='POST' onSubmit={this.onSubmitHandler}>
                    <h4 class='login100-title p-b-40'>
																						Create Group
                    </h4>

                    <div class='wrap-input100 validate-input m-b-20'>
                      <span class='btn-show-pass'>
                        <i class='fa fa fa-eye' />
                      </span>
                      <input type='text' name='name' class='input100' placeholder='Group Name' value={this.state.
name} onChange={this.onChangeHandler}  />
                      <span class='focus-input100' />
                    </div>
					
					<div class='wrap-input100 validate-input m-b-16 dropdown' data-validate='Please select batch'>
					
                      <Multiselect
              options={this.state.batch_list}
              closeIcon="close"
              displayValue="batch_name"
			  selectedValues={this.state.selected_batch_list}
			  onSelect={this.onSelect}
			  onRemove={this.onRemove}
			  selectionLimit="5"
              avoidHighlightFirstOption
			  name="batchList" 
            />
                      <span class='focus-input100' />
                    </div>
					
                    <div class='container-login100-form-btn'>
					<br />
                      <button class='login100-form-btn' type='submit'>Create</button>
					  <button class='login100-form-btn' onClick={() => this.hide_dialog_modal()}>Close</button>
                    </div>

                  </form>
</div>
)}

{this.state.popup_dialog2 && ( 
			<div class='modal-dialogs' id='dialog-modals'>
                  <form class='login100 validate-form' id="frmStudio" method='POST' onSubmit={this.onSubmitHandler2}>
                    <h4 class='login100-title p-b-40'>
																						Edit Group
                    </h4>

                    <div class='wrap-input100 validate-input m-b-20'>
                      <span class='btn-show-pass'>
                        <i class='fa fa fa-eye' />
                      </span>
                      <input type='text' name='name' class='input100' placeholder='Group Name' value={this.state.name} onChange={this.onChangeHandler}  />
                      <span class='focus-input100' />
                    </div>
					
					<div class='wrap-input100 validate-input m-b-16 dropdown' data-validate='Please select batch'>
					
                      <Multiselect
              options={this.state.batch_list}
              closeIcon="close"
              displayValue="batch_name"
			  selectedValues={this.state.selected_batch_list}
			  onSelect={this.onSelect}
			  onRemove={this.onRemove}
			  selectionLimit="5"
              avoidHighlightFirstOption
			  name="batchList" 
            />
                      <span class='focus-input100' />
                    </div>
					
                    <div class='container-login100-form-btn'>
					<br />
                      <button class='login100-form-btn' type='submit'>Update</button>
					  &nbsp;|&nbsp;<button class='login100-form-btn' onClick={() => this.hide_dialog_modal()}>Close</button>
                    </div>

                  </form>
</div>
)}

            <div class='container scheduler'>
              <div class='row'>

<div class='col-md-12'>
<br />
{this.state.isLoaded ? <ReactLoading type="cylon" color="#09AEE5" /> : ''}
<table width='100%'>
<thead><tr>
<td>
<Link to="/create-group" class="nav-link">Create Group</Link>
</td>
	
</tr></thead>
</table>
<table width='100%'>

<thead><th>Group ID</th><th>Name</th><th>Batches</th><th>Action</th></thead>
<tbody>
{this.state.group_list.map((data, key) => {return (
<tr>
	<td>{data.id}</td>
	<td>{data.name}</td>
	<td>{data.batches.map((item, key2) => {return (
	<span>{item.batch_name}, </span>
	);})}
	</td>
	<td>
		<button onClick={() => this.edit_group(data.id)}>Edit</button>
		|
		<button onClick={() => this.copy_group(data.id)}>Copy</button>
		|
	<button onClick={() => this.delete_group(data.id)}>Delete</button>
	</td></tr>
          );
        })}
</tbody>
</table>
</div>

              </div>
            </div>

          </div>
        )
  }
}

export default Group
