import React, { Component } from "react";
import { Link,Redirect } from 'react-router-dom';

class Logout extends Component{
	constructor(){
		super();
		localStorage.removeItem("token");
		window.location.href='/'
	}

	redirect(){
		window.location.href='/'
	}

	render(){

		return(

			<Link to='/'>Login to continue !</Link>
				
			);
	}
}

export default Logout;