import React, { Component } from 'react'
import Config from "../config.json";
import { Link, Redirect } from 'react-router-dom'
import ReactDOM from "react-dom";
import { Multiselect } from "multiselect-react-dropdown";
import Modal from "react-modal";
import ReactLoading from 'react-loading';
import Moment from 'moment';
import '../static/dashboard.css'
import logo2 from '../static/ZinEdu_LOGO.svg'
import '../static/sch.css'
import Header from './Header';
Modal.setAppElement("#root");
class Studio extends Component {
	
  constructor () {
    super()
    const token = localStorage.getItem('token')
	
    let login_status = true
    if (token === null) {
      login_status = false
    }
    this.state = {
	  endpoint: 'https://ap.zinedu.com/',
      login_status,
      token,
      isLoaded: true,
	  id: null,
	  studio_list: [],
	  studio: null,
	  studio_info: [],
	  studio_name: null,
	  zoom_meeting_type: 'meeting',
	  zoom_meeting_type_text: 'Select meeting type',
	  zoom_account_id: null,
	  zoom_user_id: null,
	  zoom_access_token: null,
	  zoom_refresh_token: null,
	  zoom_client_id: null,
	  zoom_client_secrete: null,
	  status:null,
      desc:null,
	  popup_dialog:false,
	  popup_dialog2: false,
    }
	this.onChangeHandler = this.onChangeHandler.bind(this)
	this.onSubmitHandler = this.onSubmitHandler.bind(this)
	this.onSubmitHandler2 = this.onSubmitHandler2.bind(this);
  }

select_meeting_type (id, text_name) {
    this.setState({
      zoom_meeting_type: id,
      zoom_meeting_type_text: text_name
    })
  }

  onChangeHandler (e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  
  onSubmitHandler (e) {
    e.preventDefault()
     const data = { zoom_meeting_type:this.state.zoom_meeting_type, studio_name: this.state.studio_name, zoom_account_id: this.state.zoom_account_id, zoom_user_id: this.state.zoom_user_id, zoom_access_token: this.state.zoom_access_token, zoom_refresh_token: this.state.zoom_refresh_token, zoom_client_id: this.state.zoom_client_id, zoom_client_secrete: this.state.zoom_client_secrete };
    (async () => {
		  const rawResponse = await fetch(Config.SERVER_URL + '/create-studio', {
		    method: 'POST',
			body: JSON.stringify(data),
			dataType: 'json',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		  })
		  const response = await rawResponse.json()
		  if(response.status){
			  alert(response.msg);
			 window.location.reload();
		  }
		  else{
			  alert(response.msg);
		  }
    })()
  }
  
  onSubmitHandler2 (e) {
    e.preventDefault()
     const data = { id: this.state.id, zoom_meeting_type: this.state.zoom_meeting_type, studio_name: this.state.studio_name, zoom_account_id: this.state.zoom_account_id, zoom_user_id: this.state.zoom_user_id, zoom_access_token: this.state.zoom_access_token, zoom_refresh_token: this.state.zoom_refresh_token, zoom_client_id: this.state.zoom_client_id, zoom_client_secrete: this.state.zoom_client_secrete };
    (async () => {
		  const rawResponse = await fetch(Config.SERVER_URL + '/save-studio', {
		    method: 'POST',
			body: JSON.stringify(data),
			dataType: 'json',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		  })
		  const response = await rawResponse.json()
		  if(response.status){
			  alert(response.msg);
			 window.location.reload();
		  }
		  else{
			  alert(response.msg);
		  }
    })()
  }

  redirect_conflict_page(){
	  return <Redirect to='/studio' />
  }

show_dialog_modal(){
	this.setState({
      popup_dialog: true
	  })
}
hide_dialog_modal(){
	this.setState({
      popup_dialog: false,
	  popup_dialog2: false,
	  })
}

edit_studio(id){
		(async () => {
      
		  const rawResponse = await fetch(Config.SERVER_URL + '/studio-info/'+id+'/', {
		    method: 'GET',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		   
		  })
		  const response = await rawResponse.json()
		  this.setState({
		  	studio_info: response.data,
			id: response.data.id,
			studio_name: response.data.studio_name,
			zoom_meeting_type: response.data.zoom_meeting_type,
			zoom_meeting_type_text: response.data.zoom_meeting_type,
			zoom_account_id: response.data.zoom_account_id,
			zoom_user_id: response.data.zoom_user_id,
			zoom_client_id: response.data.zoom_client_id,
			zoom_client_secrete: response.data.zoom_client_secrete,
			zoom_access_token: response.data.zoom_access_token,
			zoom_refresh_token: response.data.zoom_refresh_token,
			popup_dialog2: true
		  });
		  
		  
		  
    })()
}

  componentDidMount () {
    (async () => {
     
		  const rawResponse = await fetch(Config.SERVER_URL + '/studio-list/', {
		    method: 'GET',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		   
		  })
		  const content = await rawResponse.json()

		  this.setState({
		  	studio_list: content.data,
		  })
		  this.setState({
		  	isLoaded: false
		  })
    })();
	
	
	
	
  }

  
  render () {
    if (this.state.login_status === false) {
      return <Redirect to='/' />
    }
	
	
	
        
        return (
          <div>
            <Header />
			
			{this.state.popup_dialog && ( 
			<div class='modal-dialogs' id='dialog-modals'>
                  <form class='login100 validate-form' id="frmStudio" method='POST' onSubmit={this.onSubmitHandler}>
                    <h4 class='login100-title p-b-40'>
																						Create Studio
                    </h4>

                    <div class='wrap-input100 validate-input m-b-20'>
                      <span class='btn-show-pass'>
                        <i class='fa fa fa-eye' />
                      </span>
                      <input type='text' name='studio_name' class='input100' placeholder='Studio Name' value={this.state.studio_name} onChange={this.onChangeHandler}  />
                      <span class='focus-input100' />
                    </div>
					
					<div class='wrap-input100 validate-input m-b-16 dropdown' data-validate='Please select meeting type'>
                      <button class='batch100-form-btn dropdown-toggle' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'> {this.state.zoom_meeting_type_text}</button>
                      <div class='dropdown-menu' aria-labelledby='dropdownMenuButton'>
					  <option value='0' class='dropdown-item' onClick={() => this.select_meeting_type(0, 'Select meeting type')} role='button'>Select Meeting Type</option>
                      <option value='meeting' class='dropdown-item' onClick={() => this.select_meeting_type('meeting', 'Meeting')} role='button'>Meeting</option>
					<option value='webinar' class='dropdown-item' onClick={() => this.select_meeting_type('webinar', 'Webinar')} role='button'>Webinar</option>

                      </div>
                      <span class='focus-input100' />
                    </div>
					
					<div class='wrap-input100 validate-input m-b-20'>
                      <span class='btn-show-pass'>
                        <i class='fa fa fa-eye' />
                      </span>
                      <input type='text' name='zoom_account_id' class='input100' placeholder='Zoom Account ID' value={this.state.zoom_account_id} onChange={this.onChangeHandler} />
                      <span class='focus-input100' />
                    </div>
					
					<div class='wrap-input100 validate-input m-b-20'>
                      <span class='btn-show-pass'>
                        <i class='fa fa fa-eye' />
                      </span>
                      <input type='text' name='zoom_user_id' class='input100' placeholder='Zoom User ID' value={this.state.zoom_user_id} onChange={this.onChangeHandler} />
                      <span class='focus-input100' />
                    </div>
					
					<div class='wrap-input100 validate-input m-b-20'>
                      <span class='btn-show-pass'>
                        <i class='fa fa fa-eye' />
                      </span>
                      <input type='text' name='zoom_access_token' class='input100' placeholder='Zoom Access Token' value={this.state.zoom_access_token} onChange={this.onChangeHandler} />
                      <span class='focus-input100' />
                    </div>
					
					<div class='wrap-input100 validate-input m-b-20'>
                      <span class='btn-show-pass'>
                        <i class='fa fa fa-eye' />
                      </span>
                      <input type='text' name='zoom_refresh_token' class='input100' placeholder='Zoom Refresh Token' value={this.state.zoom_refresh_token} onChange={this.onChangeHandler} />
                      <span class='focus-input100' />
                    </div>
					
					<div class='wrap-input100 validate-input m-b-20'>
                      <span class='btn-show-pass'>
                        <i class='fa fa fa-eye' />
                      </span>
                      <input type='text' name='zoom_client_id' class='input100' placeholder='Zoom Client ID' value={this.state.zoom_client_id} onChange={this.onChangeHandler} />
                      <span class='focus-input100' />
                    </div>
					
					<div class='wrap-input100 validate-input m-b-20'>
                      <span class='btn-show-pass'>
                        <i class='fa fa fa-eye' />
                      </span>
                      <input type='text' name='zoom_client_secrete' class='input100' placeholder='Zoom Client Secrete' value={this.state.zoom_client_secrete} onChange={this.onChangeHandler} />
                      <span class='focus-input100' />
                    </div>
					
                    <div class='container-login100-form-btn'>
                      <button class='login100-form-btn' type='submit'>Create</button>
					  <button class='login100-form-btn' onClick={() => this.hide_dialog_modal()}>Close</button>
                    </div>

                  </form>
</div>
)}


{this.state.popup_dialog2 && ( 
			<div class='modal-dialogs' id='dialog-modals'>
                  <form class='login100 validate-form' id="frmStudio" method='POST' onSubmit={this.onSubmitHandler2}>
                    <h4 class='login100-title p-b-40'>
																						Edit Studio
                    </h4>

                    <div class='wrap-input100 validate-input m-b-20'>
                      <span class='btn-show-pass'>
                        <i class='fa fa fa-eye' />
                      </span>
                      <input type='text' name='studio_name' class='input100' placeholder='Studio Name' value={this.state.studio_name} onChange={this.onChangeHandler}  />
                      <span class='focus-input100' />
                    </div>
					
					<div class='wrap-input100 validate-input m-b-16 dropdown' data-validate='Please select meeting type'>
                      <button class='batch100-form-btn dropdown-toggle' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'> {this.state.zoom_meeting_type_text}</button>
                      <div class='dropdown-menu' aria-labelledby='dropdownMenuButton'>
					  <option value='0' class='dropdown-item' onClick={() => this.select_meeting_type(0, 'Select meeting type')} role='button'>Select Meeting Type</option>
                      <option value='meeting' class='dropdown-item' onClick={() => this.select_meeting_type('meeting', 'Meeting')} role='button'>Meeting</option>
					<option value='webinar' class='dropdown-item' onClick={() => this.select_meeting_type('webinar', 'Webinar')} role='button'>Webinar</option>

                      </div>
                      <span class='focus-input100' />
                    </div>
					
					<div class='wrap-input100 validate-input m-b-20'>
                      <span class='btn-show-pass'>
                        <i class='fa fa fa-eye' />
                      </span>
                      <input type='text' name='zoom_account_id' class='input100' placeholder='Zoom Account ID' value={this.state.zoom_account_id} onChange={this.onChangeHandler} />
                      <span class='focus-input100' />
                    </div>
					
					<div class='wrap-input100 validate-input m-b-20'>
                      <span class='btn-show-pass'>
                        <i class='fa fa fa-eye' />
                      </span>
                      <input type='text' name='zoom_user_id' class='input100' placeholder='Zoom User ID' value={this.state.zoom_user_id} onChange={this.onChangeHandler} />
                      <span class='focus-input100' />
                    </div>
					
					<div class='wrap-input100 validate-input m-b-20'>
                      <span class='btn-show-pass'>
                        <i class='fa fa fa-eye' />
                      </span>
                      <input type='text' name='zoom_access_token' class='input100' placeholder='Zoom Access Token' value={this.state.zoom_access_token} onChange={this.onChangeHandler} />
                      <span class='focus-input100' />
                    </div>
					
					<div class='wrap-input100 validate-input m-b-20'>
                      <span class='btn-show-pass'>
                        <i class='fa fa fa-eye' />
                      </span>
                      <input type='text' name='zoom_refresh_token' class='input100' placeholder='Zoom Refresh Token' value={this.state.zoom_refresh_token} onChange={this.onChangeHandler} />
                      <span class='focus-input100' />
                    </div>
					
					<div class='wrap-input100 validate-input m-b-20'>
                      <span class='btn-show-pass'>
                        <i class='fa fa fa-eye' />
                      </span>
                      <input type='text' name='zoom_client_id' class='input100' placeholder='Zoom Client ID' value={this.state.zoom_client_id} onChange={this.onChangeHandler} />
                      <span class='focus-input100' />
                    </div>
					
					<div class='wrap-input100 validate-input m-b-20'>
                      <span class='btn-show-pass'>
                        <i class='fa fa fa-eye' />
                      </span>
                      <input type='text' name='zoom_client_secrete' class='input100' placeholder='Zoom Client Secrete' value={this.state.zoom_client_secrete} onChange={this.onChangeHandler} />
                      <span class='focus-input100' />
                    </div>
					
                    <div class='container-login100-form-btn'>
                      <button class='login100-form-btn' type='submit'>Update</button>
					  <button class='login100-form-btn' onClick={() => this.hide_dialog_modal()}>Close</button>
                    </div>
				
                  </form>
</div>
)}
            <div class='container scheduler'>
              <div class='row'>

<div class='col-md-12'>
<br />
<table width='100%'>
<thead><tr>
<td><a href='javascript:void(0)' onClick={() => this.show_dialog_modal()}>Create Studio</a></td>
	
</tr></thead>
</table>
{this.state.isLoaded ? <ReactLoading type="cylon" color="#09AEE5" /> : ''}
<table width='100%'>

<thead><th>Id</th><th>Name</th><th>Action</th></thead>
<tbody>
{this.state.studio_list.map((data, key) => {
 return (
<tr>
	<td>{data.id}</td>
	<td>{data.studio_name}</td>
	<td><button onClick={() => this.edit_studio(data.id)}>Edit</button></td></tr>
          );
        })}
</tbody>
</table>
</div>

              </div>
            </div>

          </div>
        )
  }
}

export default Studio
