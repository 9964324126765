import React, { Component } from 'react'
import Config from "../config.json";
import { Link, Redirect } from 'react-router-dom'
import ReactDOM from "react-dom";
import { Multiselect } from "multiselect-react-dropdown";
import Modal from "react-modal";
import ReactLoading from 'react-loading';
import Moment from 'moment';
import '../static/dashboard.css'
import logo2 from '../static/ZinEdu_LOGO.svg'
import '../static/sch.css'
import Header from './Header';
Modal.setAppElement("#root");
class TeacherReport extends Component {
	
  constructor () {
    super()
    const token = localStorage.getItem('token')
	
    let login_status = true
    if (token === null) {
      login_status = false
    }
    this.state = {
	  endpoint: 'https://ap.zinedu.com/',
      login_status,
      token,
	  filter_date: null,
	  teacher_id: null,
	  teacher_text: 'Select teacher',
      isLoaded: true,
	  teacher_available: [],
	  start_hour_list: [],
	  end_hour_list: [],
	  weekday_list: ["",'SUN','MON','TUE','WED','THU','FRI','SAT'],
	  weekday_num_list: ["",1,2,3,4,5,6,7],
	  time_list: ['00:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00'],
    }
this.onChangeHandler = this.onChangeHandler.bind(this)
  }


  
  select_teacher (id, teacher_name) {
	 
    this.setState({
      teacher_id: id,
      teacher_text: teacher_name
    })
	
	if(this.state.teacher_id && this.state.filter_date){
		this.get_reports();
	}
  }
  
  onChangeHandler (e) {
    this.setState({
      filter_date: e.target.value
    })
	if(this.state.teacher_id && this.state.filter_date){
		this.get_reports();
	}
  }

get_reports(){
	
	(async () => {
     
		  const rawResponse = await fetch(Config.SERVER_URL + '/get-teacher-report?filter_date='+this.state.filter_date+'&teacher_id='+this.state.teacher_id, {
		    method: 'GET',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		   
		  })
		  const content = await rawResponse.json()
		  
		  

		  
    })();
}

  componentDidMount () {
     (async () => {
      
		  const rawResponse = await fetch(Config.SERVER_URL + '/get-teachers/', {
		    method: 'GET',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		   
		  })
		  const content = await rawResponse.json()

		  this.setState({
		  	teacher_available: content.data
		  })
    })();
  }

  
  render () {
    if (this.state.login_status === false) {
      return <Redirect to='/' />
    }
	
	
	
        
        return (
          <div>
            <Header />
            <div class='container scheduler'>
			<div class='row'>
			<div class='col-md-12'>
			<div class='wrap-input100 validate-input m-b-16 dropdown' data-validate='Please Teacher'>
                      <button class='batch100-form-btn dropdown-toggle' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'> {this.state.teacher_text}</button>
                      <div class='dropdown-menu' aria-labelledby='dropdownMenuButton'>
					  <option value='0' class='dropdown-item' onClick={() => this.select_teacher(0, 'Teacher')} role='button'>Teacher</option>
                        {this.state.teacher_available.map((item, i) => (
                          <option value='{item.teacher_name}' class='dropdown-item' onClick={() => this.select_teacher(item.id, item.teacher_name)} role='button'>{item.teacher_name}</option>
                        ))}

                      </div>
                      <span class='focus-input100' />
                    </div>
					<div class='wrap-input100 validate-input m-b-16' data-validate='Please enter From Date'>
                      <input type='date' name='filter_date' class='input100' id='usericon' placeholder='Date' value={this.state.filter_date} onChange={this.onChangeHandler} />
                      <span class='focus-input100' />
                    </div>
			</div>
			
			</div>
              <div class='row'>

<div class='col-md-12'>
<table width="100%" border="1">
<tr>
{this.state.weekday_list.map((data, key) => {return (
<td>{data}</td>
    );
})}
</tr>
<tr>
{this.state.weekday_num_list.map((data, key) => {return (
<td>{data}</td>
    );
})}
</tr>

{this.state.time_list.map((data, key) => {return (
<tr><td>{data}</td>
{this.state.weekday_num_list.map((data, key) => {if(data){return (
<td></td>

);}
})}
</tr>
    );
})}
</table>
</div>

              </div>
            </div>

          </div>
        )
  }
}

export default TeacherReport