import React, { Component } from "react";
import Config from "../config.json";

class StudentForm extends Component{
	constructor(){
		super();
		let login_status = false;
		let token = "ae8403861f5e391abd6ad63c9b1dc69601334f79";
		
		
			this.state = {
				name:'',
				current_class:'',
				email:'',
				pno:'',
				school:'',
				father_name:'',
				father_pno:'',
				token:token
			}
			
		

		

		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
		// console.log(login_status);
	}

	onChangeHandler(e){
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	onSubmitHandler(e){
		(async () => {
		  const rawResponse = await fetch(Config.SERVER_URL + '/post-student-data/?name='+this.state.name+'&current_class='+this.state.current_class+'&email='+this.state.email+'&pno='+this.state.pno+'&school='+this.state.school+'&father_name='+this.state.father_name+'&father_pno='+this.state.father_pno, {
		    method: 'GET',
		    headers: {
		      'Accept': 'application/json',
		      'Content-Type': 'application/json',
		      'Authorization': 'Token '+this.state.token,
		    },
		    // body: JSON.stringify(data)
		  });
		  const content = await rawResponse.json();

		  console.log(content);

		  

		})();
	}

	render(){
		return(
				<div class="container-sm" >
					<h2 class="text-white"> ZinEdu Classes Student Information </h2><br/>
					<form onSubmit={this.onSubmitHandler}>
						<div class="form-group row">
						    <label for="staticEmail" class="col-sm-2 col-form-label text-white">Name</label>
						    <div class="col-sm-10">
						      <input type="text" class="form-control" name="name" placeholder="Student Name" value={this.state.name} onChange={this.onChangeHandler} />
						    </div>
						 </div>
						 <div class="form-group row">
						    <label for="staticEmail" class="col-sm-2 col-form-label text-white">Current Class</label>
						    <div class="col-sm-10">
						      <input type="text" class="form-control" name="current_class" placeholder="Current Class" value={this.state.current_class} onChange={this.onChangeHandler} />
						    </div>
						 </div>
						 <div class="form-group row">
						    <label for="staticEmail" class="col-sm-2 col-form-label text-white">Email</label>
						    <div class="col-sm-10">
						      <input type="email" class="form-control" name="email" placeholder="Email" value={this.state.email} onChange={this.onChangeHandler} />
						    </div>
						 </div>
						 <div class="form-group row">
						    <label for="staticEmail" class="col-sm-2 col-form-label text-white">Phone Number</label>
						    <div class="col-sm-10">
						      <input type="number" class="form-control" name="pno" placeholder="Mobile Number" value={this.state.pno} onChange={this.onChangeHandler} />
						    </div>
						 </div>
						 <div class="form-group row">
						    <label for="staticEmail" class="col-sm-2 col-form-label text-white">School</label>
						    <div class="col-sm-10">
						      <input type="text" class="form-control" name="school" placeholder="School Name" value={this.state.school} onChange={this.onChangeHandler} />
						    </div>
						 </div>
						 <div class="form-group row">
						    <label for="staticEmail" class="col-sm-2 col-form-label text-white">Father's Name</label>
						    <div class="col-sm-10">
						      <input type="text" class="form-control" name="father_name" placeholder="Father's Name" value={this.state.father_name} onChange={this.onChangeHandler} />
						    </div>
						 </div>
						 <div class="form-group row">
						    <label for="staticEmail" class="col-sm-2 col-form-label text-white">Father's Phone Number</label>
						    <div class="col-sm-10">
						      <input type="text" class="form-control" name="father_pno" placeholder="Father's Mobile Number" value={this.state.father_pno} onChange={this.onChangeHandler} />
						    </div>
						 </div>
						
						<button type="submit" class="btn btn-primary">Submit</button>

					</form>
					
				</div>
			);
			
	}

}

export default StudentForm;